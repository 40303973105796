import React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

const CartPriceAccordian = ({ cartItems = null, currencyFormatter }) => {
  return (
    <>
      <Accordion className="rounded-ss-xl rounded-se-xl">
        <AccordionSummary
          sx={{
            backgroundColor: "#fed7aa",
            color: "#ea580c",
            // backgroundColor: "#9333ea",
            // color: "#ffffff",
            borderStartStartRadius: "0.75rem",
            borderStartEndRadius: "0.75rem",
            marginY: 0,
            paddingY: 0,
          }}
          expandIcon={<ExpandMoreIcon sx={{ color: "#f97316" }} />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="bg-orange-200"
        >
          <div className="flex items-center">
            <h6 className="text-orange-500 font-bold mb-0 mr-3 text-base">Your Total Price</h6>
            <h6 className="mb-0 text-orange-500 font-bold text-2xl">
              {currencyFormatter.format(
                (cartItems &&
                  cartItems["parts"].reduce((sum, cartItem) => {
                    return sum + cartItem?.listPrice * cartItem?.quantity;
                  }, 0) +
                    (cartItems["parts"].reduce((sum, cartItem) => {
                      return sum + cartItem?.listPrice * cartItem?.quantity;
                    }, 0) *
                      10) /
                      100 -
                    (cartItems["parts"].reduce((sum, cartItem) => {
                      return sum + cartItem?.listPrice * cartItem?.quantity;
                    }, 0) *
                      2) /
                      100 +
                    500) ||
                  0
              )}
            </h6>
          </div>
        </AccordionSummary>
        <AccordionDetails className="border border-gray-300 px-0">
          <div className="py-1">
            <div className="flex justify-between">
              <p className="mb-0">Currency</p>
              <h6 className="mb-0">
                <b>{"INR"}</b>
              </h6>
            </div>
            <div className="border my-2 border-gray-300"></div>
            <div className="flex justify-between items-center">
              <p className="mb-0">Spare Parts</p>
              <h6 className="mb-0">
                <b>
                  {currencyFormatter.format(
                    (cartItems &&
                      cartItems["parts"].reduce((sum, cartItem) => {
                        return sum + cartItem?.listPrice * cartItem?.quantity;
                      }, 0)) ||
                      0
                  )}
                </b>
              </h6>
            </div>
            <div className="border my-2 border-gray-300"></div>
            <div className="flex justify-between items-center">
              <p className="mb-0">Labor Charge</p>
              <h6 className="mb-0">
                <b>{currencyFormatter.format(500)}</b>
              </h6>
            </div>
            <div className="border my-2 border-gray-300"></div>
            <div className="flex justify-between items-center">
              <p className="mb-0">Misc.</p>
              <h6 className="mb-0">
                <b>{currencyFormatter.format(0)}</b>
              </h6>
            </div>
            <div className="border my-2 border-gray-300"></div>
            <div className="flex justify-between items-center">
              <p className="mb-0">Tax</p>
              <h6 className="mb-0">
                <b>
                  {currencyFormatter.format(
                    (cartItems &&
                      (cartItems["parts"].reduce((sum, cartItem) => {
                        return sum + cartItem?.listPrice * cartItem?.quantity;
                      }, 0) *
                        10) /
                        100) ||
                      0
                  )}
                </b>
              </h6>
            </div>
            <div className="border my-2 border-gray-300"></div>
            <div className="flex justify-between items-center">
              <div className="flex items-center mb-0">
                <p className="mb-0">Discount</p>
                <p className="border border-gray-400 rounded-xl px-2 py-1 ml-1 mb-0">2% | REP</p>
              </div>
              <h6 className="mb-0">
                <b>
                  -
                  {currencyFormatter.format(
                    (cartItems &&
                      (cartItems["parts"].reduce((sum, cartItem) => {
                        return sum + cartItem?.listPrice * cartItem?.quantity;
                      }, 0) *
                        2) /
                        100) ||
                      0
                  )}
                </b>
              </h6>
            </div>
            <div className="border my-2 border-gray-300"></div>
            <div className="flex justify-between items-center">
              <p className="mb-0">Total</p>
              <h6 className="mb-0">
                <b>
                  {currencyFormatter.format(
                    (cartItems &&
                      cartItems["parts"].reduce((sum, cartItem) => {
                        return sum + cartItem?.listPrice * cartItem?.quantity;
                      }, 0) +
                        (cartItems["parts"].reduce((sum, cartItem) => {
                          return sum + cartItem?.listPrice * cartItem?.quantity;
                        }, 0) *
                          10) /
                          100 -
                        (cartItems["parts"].reduce((sum, cartItem) => {
                          return sum + cartItem?.listPrice * cartItem?.quantity;
                        }, 0) *
                          2) /
                          100 +
                        500) ||
                      0
                  )}
                </b>
              </h6>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default CartPriceAccordian;
