import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import HomeScreen from "./components/HomeScreen";

import { CART, HOME, TRACK_ORDER } from "components/navigation/NavigationConstent";
import CartMaster from "components/shopping-cart/CartMaster";
import TrackOrderDetails from "components/order/TrackOrderDetails";
import NotFoundPage from "components/navigation/NotFoundPage";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={HOME} element={<Dashboard />}>
        <Route index element={<HomeScreen />} />
        <Route path={CART} element={<CartMaster />} />
        <Route path={TRACK_ORDER} element={<TrackOrderDetails />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
