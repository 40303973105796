import React from "react";

import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";

import Moment from "react-moment";
import { Link } from "react-router-dom";

const TrackOrderDetails = () => {
  return (
    <>
      <div className="grid grid-cols-12 gap-4 mt-4">
        <div className="col-span-1">
          <Link to="/" className="flex justify-center items-center border h-6 w-6 rounded-full border-gray-300 text-gray-400">
            <KeyboardBackspaceOutlinedIcon sx={{ fontSize: "20px" }} />
          </Link>
        </div>
        <div className="col-span-10 text-center">
          <h4 className="flex justify-center align-middle text-lg font-semibold">Tracking Details</h4>
        </div>
      </div>
      <h4 className="font-bold text-base my-2">Order #N9JTVA3L7Y4V</h4>

      <div className="border border-gray-300 rounded-2xl">
        <table class="table w-full ">
          <thead className="border-b bg-orange-600">
            <tr className="rounded-2xl">
              <th className="py-2 text-white font-bold ">Part Number</th>
              <th className="py-2 text-white font-bold border-x border-white">Quantity</th>
              <th className="py-2 text-white font-bold">Price</th>
            </tr>
          </thead>
          <tbody className="py-3">
            <tr className="border-b border-gray-300 text-center py-4">
              <td className="py-2 font-normal">Witchy Woman</td>
              <td className="py-2 font-normal border-x border-gray-300">The Eagles</td>
              <td className="py-2 font-normal">1972</td>
            </tr>
            <tr className="border-b border-gray-300 text-center py-4">
              <td className="py-2 font-normal">Witchy Woman</td>
              <td className="py-2 font-normal border-x border-gray-300">The Eagles</td>
              <td className="py-2 font-normal">1972</td>
            </tr>
            <tr className="text-center py-4">
              <td className="py-2 font-normal">Witchy Woman</td>
              <td className="py-2 font-normal border-x border-gray-300">The Eagles</td>
              <td className="py-2 font-normal">1972</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="border border-gray-300 my-4" />
      <div className="card border border-gray-300 rounded-xl px-3 py-1 my-3">
        <div className="flex items-baseline my-3">
          <span className="text-orange-600 font-bold">
            <FmdGoodOutlinedIcon />
          </span>
          <div className="ml-3">
            <p className="text-gray-500 font-medium">From</p>
            <span className="font-semibold text-sm">Gambir District, West Jakarta City, DKI Jakarta 12410 Indonesia</span>
          </div>
        </div>
        <div className="flex items-baseline my-3">
          <span className="text-orange-600 font-bold">
            <LocalShippingOutlinedIcon />
          </span>
          <div className="ml-3">
            <p className="text-gray-500 font-medium">Send to</p>
            <span className="font-semibold text-sm leading-none">
              JI. Rose No. 123 Block A, Cipete Sub-District, Cilandak District, South Jakarta City, DKI Jakarta 12410 Indonesia
            </span>
          </div>
        </div>
      </div>
      <div className="border border-gray-300 my-4" />
      <div className="mt-4">
        <ol className="relative ms-3 border-s border-orange-600">
          <li className="mb-10 ms-6">
            <span className="absolute -start-2 flex h-4 w-4 items-center justify-center rounded-full ring-8 ring-white bg-orange-600 top-1.5"></span>
            <div class="grid grid-cols-4 gap-3">
              <div class="col-span-3">
                <h4 className="mb-0.5 text-base font-semibold">Being Packaged</h4>
                <p className="text-sm font-normal text-gray-500 dark:text-gray-400">Waiting for the order to be sent to the delivery service</p>
              </div>
              <div className="col-span-1 text-end">
                <Moment format="DD MMM YYYY" className="font-medium text-gray-400">
                  {"2024-08-25"}
                </Moment>
              </div>
            </div>
          </li>
          <li className="mb-10 ms-6">
            <span className="absolute -start-2 flex h-4 w-4 items-center justify-center rounded-full ring-8 ring-white bg-orange-600 mt-2"></span>
            <div class="grid grid-cols-4 gap-3">
              <div class="col-span-3">
                <h4 className="mb-0.5 text-base font-semibold">Orders in Delivery</h4>
                <p className="text-sm font-normal text-gray-500 dark:text-gray-400">Orders are being shipped to tansh </p>
              </div>
              <div className="col-span-1 text-end">
                <Moment format="DD MMM YYYY" className="font-medium text-gray-400">
                  {"2024-08-26"}
                </Moment>
              </div>
            </div>
          </li>
          <li className="mb-10 ms-6">
            <span className="absolute -start-2 flex h-4 w-4 items-center justify-center rounded-full ring-8 ring-white bg-orange-600 mt-2"></span>
            <div class="grid grid-cols-4 gap-3">
              <div class="col-span-3">
                <h4 className="mb-0.5 text-base font-semibold">Orders in Delivery</h4>
                <p className="text-sm font-normal text-gray-500 dark:text-gray-400">The Order has arrived at the transit location</p>
              </div>
              <div className="col-span-1 text-end">
                <Moment format="DD MMM YYYY" className="font-medium text-gray-400">
                  {"2024-08-27"}
                </Moment>
              </div>
            </div>
          </li>
          <li className="ms-6 border-none">
            <span className="absolute -start-2 flex h-4 w-4 items-center justify-center rounded-full bg-primary-100 ring-4 ring-white dark:bg-primary-900 ring-orange-500"></span>
            <div class="grid grid-cols-4 gap-3">
              <div class="col-span-3">
                <h4 className="mb-0.5 text-base font-semibold">Towards Destination</h4>
                <p className="text-sm font-normal text-gray-500 dark:text-gray-400">Order to destination address</p>
              </div>
              <div className="col-span-1 text-end">
                <Moment format="DD MMM YYYY" className="font-medium text-gray-400">
                  {"2024-08-27"}
                </Moment>
              </div>
            </div>
          </li>
        </ol>
      </div>
      <div class="grid grid-rows-1 grid-flow-col mt-4 mb-2">
        <button className="bg-orange-600 text-white rounded-xl py-2 text-center px-2">Live Tracking</button>
      </div>
    </>
  );
};

export default TrackOrderDetails;
