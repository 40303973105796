import React from "react";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { currencyFormatter } from "utils/currencyFormatter";

const style = {
  position: "absolute",
  //   top: "50%",
  bottom: "0%",
  left: "50%",
  //   transform: "translate(-50%, -50%)",
  transform: "translate(-50%, 0%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  pt: 1,
  px: 2,
  pb: 3,
};

const PaymentModel = ({ show, closeModal, handleSnack, cartItems }) => {
  //
  const handleCheckOutOrder = () => {
    closeModal();
  };

  return (
    <Modal
      open={show}
      onClose={closeModal}
      className="rounded-t-lg"
      // className="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
    >
      <Box sx={{ ...style, width: "92vw" }} className="rounded-t-xl">
        <div className="flex justify-between items-center">
          <h4 className="font-semibold text-xl">Payment Method</h4>
          <h4 className="font-bold text-xl text-gray-600 cursor-pointer" onClick={closeModal}>
            X
          </h4>
        </div>
        <div className="card border border-gray-300 px-2 mt-2 rounded-xl">
          <div className="flex justify-between items-center">
            <div className="flex items-center justify-around">
              <img src="../../assets/images/mastercard_logo.png" alt="" width={70} />
              <div>
                <h4 className="font-semibold">**** 1234</h4>
                <span className="text-gray-600">Payment Method</span>
              </div>
            </div>
            <p className="text-2xl text-gray-500 font-light">
              <KeyboardArrowRightIcon />
            </p>
          </div>
        </div>
        <div className="card border border-gray-300 px-2 mt-2 rounded-xl">
          <div className="flex justify-between items-center">
            <div className="flex items-center justify-around">
              <img src="../../assets/images/discountPromo.png" alt="" width={60} />
              <div>
                <h4 className="font-bold">Promo Code or Voucher</h4>
                <span className="text-gray-600">Savings with Your Promo Code!</span>
              </div>
            </div>
            <p className="text-2xl text-gray-500 font-light">
              <KeyboardArrowRightIcon />
            </p>
          </div>
        </div>
        <div className="flex justify-between mt-2">
          <p className="text-gray-500 font-semibold">Sub Total</p>
          <p className="font-semibold">
            {currencyFormatter.format(
              (cartItems &&
                cartItems["parts"].reduce((sum, cartItem) => {
                  return sum + cartItem?.listPrice * cartItem?.quantity;
                }, 0)) ||
                0
            )}
          </p>
        </div>
        <div className="flex justify-between mt-2">
          <p className="text-gray-500 font-semibold">Shipping</p>
          <p className="font-semibold">{currencyFormatter.format(500)}</p>
        </div>
        <div className="flex justify-between mt-2">
          <p className="text-gray-500 font-semibold">Total Payment</p>
          <p className="font-bold text-xl">
            {currencyFormatter.format(
              (cartItems &&
                cartItems["parts"].reduce((sum, cartItem) => {
                  return sum + cartItem?.listPrice * cartItem?.quantity;
                }, 0) +
                  (cartItems["parts"].reduce((sum, cartItem) => {
                    return sum + cartItem?.listPrice * cartItem?.quantity;
                  }, 0) *
                    10) /
                    100 -
                  (cartItems["parts"].reduce((sum, cartItem) => {
                    return sum + cartItem?.listPrice * cartItem?.quantity;
                  }, 0) *
                    2) /
                    100 +
                  500) ||
                0
            )}
          </p>
        </div>
        <div class="grid grid-rows-1 grid-flow-col mt-3">
          <button className="bg-orange-600 text-white rounded-full py-2 text-center px-2" onClick={handleCheckOutOrder}>
            Checkout
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default PaymentModel;
