import React, { useContext, useEffect, useState } from "react";

import MoneyIcon from "@mui/icons-material/Money";
// import DeleteIcon from "@mui/icons-material/Delete";
import PinDropIcon from "@mui/icons-material/PinDrop";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";

import { Divider, Tooltip } from "@mui/material";

import { currencyFormatter } from "utils/currencyFormatter";
import { PartsCartContext } from "context/PartsCartContext";
import PartsCard from "components/parts-master/PartsCard";
import { getCartParts } from "api/searchServices";
import { Link } from "react-router-dom";
import CartPriceAccordian from "./CartPriceAccordian";
import PaymentModel from "./PaymentModel";
import CustomizedSnackbar from "components/common/CustomSnackBar";

const CartMaster = () => {
  const { cartItems, setCartItems } = useContext(PartsCartContext);
  const [activeStep, setActiveStep] = useState(0);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);

  // Snack Bar State
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  useEffect(() => {
    getPartsCartItems();
  }, []);

  // get the part cart items
  const getPartsCartItems = async () => {
    await getCartParts(91).then((res) => {
      if (res) {
        setCartItems(res);
      } else {
        setCartItems(null);
      }
    });
  };

  // Checkout & Go to shping Details Modal
  const handleCheckOutCart = async () => {
    setActiveStep(activeStep + 1);
  };

  // handle Back to Previous Screen
  const handleBackToScreen = async () => {
    setActiveStep(activeStep - 1);
  };

  // show the payment modal
  const handleShowPaymentModal = () => {
    setOpenPaymentModal(true);
  };

  // cart Details
  const cartDetails = () => {
    return (
      <>
        <div className="card shadow-lg px-2 py-4">
          <h4 className="text-xl font-semibold">Shopping Cart</h4>
          <div className="flex justify-between items-center">
            <h5 className="font-semibold">{cartItems && `#${cartItems?.cartNumber}`}</h5>
            {cartItems && (cartItems?.parts.length !== 0 || cartItems.portfolios.length !== 0) ? (
              <>
                {/* <button className="border-2 h-8 w-8 border-purple-600 text-purple-600 flex justify-center items-center hover:bg-purple-700 hover:text-white md:hidden rounded-full">
                  <Tooltip title="Delete All">
                    <DeleteIcon style={{ fontSize: "20px" }} />
                  </Tooltip>
                </button> */}
                <Tooltip title="Delete All">
                  <button className="border px-2 py-1 border-purple-600 text-purple-600 flex justify-center items-center hover:bg-purple-700 hover:text-white md:hidden rounded-xl">
                    Clear All
                  </button>
                </Tooltip>
                <button className="border-2 border-purple-600 text-purple-600 p-1 px-2 text-base hidden rounded md:block hover:bg-purple-700 hover:text-white">
                  Delete All
                </button>
              </>
            ) : (
              <>
                <button className="border-2 h-8 w-8 border-purple-600 text-purple-600 flex justify-center items-center md:hidden rounded-full">
                  <Tooltip title="Back">
                    <KeyboardBackspaceIcon style={{ fontSize: "20px" }} />
                  </Tooltip>
                </button>
                <button className="border-2 border-purple-600 text-purple-600 p-1 px-2 text-base hidden rounded md:block">
                  <KeyboardBackspaceIcon /> Back
                </button>
              </>
            )}
          </div>
          <Divider sx={{ my: 2 }} />
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 overflow-y-scroll h-[375px] md:h-4/6">
            {cartItems &&
              cartItems?.parts.length !== 0 &&
              cartItems?.parts.map((partItem) => <PartsCard key={partItem?.id} partItem={partItem} isCartItem={true} />)}
          </div>
        </div>
        <div>
          <CartPriceAccordian cartItems={cartItems} currencyFormatter={currencyFormatter} />
        </div>
        <div class="grid grid-cols-2 gap-4 mt-2">
          <Link
            to="/"
            className="border border-purple-700 text-purple-700 font-semibold rounded-xl py-2 text-center hover:bg-purple-300 hover:border-purple-300 hover:text-white"
          >
            Cancel
          </Link>
          <button className="border flex justify-center items-start bg-purple-700 text-white py-2 rounded-xl" onClick={handleCheckOutCart}>
            Checkout
          </button>
        </div>
      </>
    );
  };

  // Shipping Details
  const shippingDetails = () => {
    return (
      <>
        <div className="grid grid-cols-12 gap-4 my-3">
          <div className="col-span-1">
            <span className="flex justify-center items-center border h-6 w-6 rounded-full border-gray-300 text-gray-400" onClick={handleBackToScreen}>
              <KeyboardBackspaceOutlinedIcon sx={{ fontSize: "20px" }} />
            </span>
          </div>
          <div className="col-span-10 text-center">
            <h5 className="font-semibold text-base">Checkout</h5>
          </div>
        </div>
        <div className="card border border-gray-300 rounded-2xl px-2 py-4 mt-2">
          <div className="flex items-baseline gap-4">
            <div>
              <PinDropIcon />
            </div>
            <div className="flex items-center">
              <div>
                <h5 className="font-medium text-gray-600">Shipping Address</h5>
                <div>
                  <p className="text-wrap text-sm text-gray-500 leading-0 mt-2">
                    <span className="bg-orange-100 text-orange-600 px-2 py-1 font-semibold rounded-full mr-2">Home</span>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ex eum ratione odit quaerat.
                  </p>
                  <p className="text-gray-600 font-medium">
                    Albert Flores
                    <span className="text-gray-400 font-light mx-2">+62 874 3190 1715</span>
                  </p>
                </div>
              </div>
              <p className="text-2xl text-gray-500 font-light">
                <KeyboardArrowRightIcon />
              </p>
            </div>
          </div>
        </div>
        <div className="border border-gray-300 rounded-2xl my-4">
          <table class="table w-full ">
            <thead className="border-b bg-orange-600">
              <tr className="rounded-2xl">
                <th className="py-2 text-white font-bold ">Part</th>
                <th className="py-2 text-white font-bold border-x border-white">Quantity</th>
                <th className="py-2 text-white font-bold">Price</th>
              </tr>
            </thead>
            <tbody className="py-3">
              {cartItems &&
                cartItems?.parts.length !== 0 &&
                cartItems?.parts.map((item, i) => (
                  <tr className={`${i === cartItems?.parts.length - 1 ? "" : "border-b border-gray-300"} text-center py-4`} key={item?.id}>
                    <td className="py-2 font-normal text-left pl-3">
                      <h5 className="test-sm mb-0 leading-3">{item?.partNumber}</h5>
                      <p className="text-gray-400 text-xs">{item?.partDescription}</p>
                    </td>
                    <td className="py-2 font-normal border-x border-gray-300">{item?.quantity}</td>
                    <td className="py-2 font-normal">
                      {currencyFormatter.format(item?.quantity * item?.listPrice)}
                      <p className="text-gray-400 text-xs">
                        ({currencyFormatter.format(item?.listPrice)} * {item?.quantity})
                      </p>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between items-center mt-3">
          <h4 className="text-lg font-bold">Select Shipping</h4>
          <h5 className="text-orange-600 font-semibold text-sm">See all options</h5>
        </div>
        <div className="card border border-gray-200 rounded-2xl px-2 py-3 mt-3 flex justify-between items-center">
          <div>
            <h5 className="font-semibold mb-1">Express</h5>
            <p className="text-gray-500 text-sm font-light">Estimated arrived 9-10 September</p>
          </div>
          <h4 className="text-xl font-bold">{currencyFormatter.format(1000)}</h4>
        </div>
        <div className="flex justify-between items-center mt-3">
          <h5 className="font-semibold mb-1">Note</h5>
          <input
            type="text"
            className="border-none text-sm text-gray-500 focus:outline-none focus:ring-0 focus:border-none"
            placeholder="Type Any Message..."
          />
        </div>
        <div className="flex justify-between items-center mt-3">
          <h5 className="font-semibold mb-1">Subtotal, {(cartItems && cartItems?.parts.length) || 0} items</h5>
          <h4 className="text-xl text-orange-600 font-bold">
            {currencyFormatter.format(
              (cartItems &&
                cartItems["parts"].reduce((sum, cartItem) => {
                  return sum + cartItem?.listPrice * cartItem?.quantity;
                }, 0)) ||
                0
            )}
          </h4>
        </div>
        <Divider sx={{ my: 2 }} />
        <div>
          <h4>Payment Method</h4>
          <div className="grid grid-cols-2 gap-4 mt-2">
            <div className="card border border-orange-600 rounded-xl bg-orange-50 p-2">
              <div className="flex">
                <span className="text-orange-600">
                  <MoneyIcon sx={{ fontSize: "20px" }} />
                </span>
                <h4 className="text-orange-600 font-bold text-base mx-2">Cash</h4>
              </div>
              <p className="text-orange-500 font-semibold leading-4 text-xs mt-1">Pay Cash Lorem ipsum dolor sit amet</p>
            </div>
            <div className="card border border-gray-300 rounded-xl p-2">
              <div className="flex">
                <span className="text-orange-600 text-base">
                  <AccountBalanceIcon sx={{ fontSize: "20px" }} />
                </span>
                <h4 className="font-bold text-base mx-2">Bank Tansfer</h4>
              </div>
              <p className="font-semibold leading-4 text-xs mt-1">Pay Cash Lorem ipsum dolor sit amet</p>
            </div>
          </div>
        </div>
        <div className="shadow-lg  border-t border-gray-200 flex justify-between items-center mt-4 px-2 py-3">
          <div>
            <p className="text-gray-500 text-sm">Total</p>
            <h4 className="font-semibold text-lg">
              {currencyFormatter.format(
                (cartItems &&
                  cartItems["parts"].reduce((sum, cartItem) => {
                    return sum + cartItem?.listPrice * cartItem?.quantity;
                  }, 0) +
                    (cartItems["parts"].reduce((sum, cartItem) => {
                      return sum + cartItem?.listPrice * cartItem?.quantity;
                    }, 0) *
                      10) /
                      100 -
                    (cartItems["parts"].reduce((sum, cartItem) => {
                      return sum + cartItem?.listPrice * cartItem?.quantity;
                    }, 0) *
                      2) /
                      100 +
                    500) ||
                  0
              )}
            </h4>
          </div>
          <button
            className="bg-orange-600 text-white py-1 px-2 border rounded-lg hover:text-orange-600 hover:bg-white hover:border-orange-600"
            onClick={handleShowPaymentModal}
          >
            Checkout
          </button>
        </div>
      </>
    );
  };

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />

      {activeStep === 0 && cartDetails()}
      {activeStep === 1 && shippingDetails()}
      {openPaymentModal && (
        <PaymentModel show={openPaymentModal} closeModal={() => setOpenPaymentModal(false)} handleSnack={handleSnack} cartItems={cartItems} />
      )}
    </>
  );
};

export default CartMaster;
