import Loader from "react-js-loader";

const LoadingProgress = () => {
  return (
    <div className="flex items-center justify-center">
      <Loader type="spinner-default" bgColor={"#872ff7"} title={"Loading"} color={"#FFFFFF"} size={35} />
    </div>
  );
};

export default LoadingProgress;
