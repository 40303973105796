import React, { createContext, useReducer, useMemo } from "react";

// import Cookies from "js-cookie";

import PartsCartProvider from "./PartsCartContext";
import { KEYS, LocalStorageActions } from "api/LocalData";
import authReducer, { initialState } from "../reducers/AuthReducer";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, dispatch] = useReducer(authReducer, initialState);
  const authActions = useMemo(
    () => ({
      //For successful Signin
      signIn: async (data) => {
        //Set the auth data in context
        dispatch({
          actionType: "LOGIN",
          accessToken: data.accessToken,
          userId: data.userId,
          role: data.role,
          userEmail: data.userEmail,
          tenantId: data.tenantId,
          planName: data.planName,
          customerId: data.customerId,
          customerName: data.customerName,
        });

        // Store Auth Data in local storage
        LocalStorageActions.store(
          KEYS.USER_DATA,
          JSON.stringify({
            accessToken: data.accessToken,
            role: data.role,
            userId: data.userId,
            userEmail: data.userEmail,
            tenantId: data.tenantId,
            planName: data.planName,
            customerId: data.customerId,
            customerName: data.customerName,
          })
        );
        var cookiesData = {
          user_tenantId: data.tenantId,
          user_userId: data.userId,
          user_userEmail: data.userEmail,
          user_accessToken: data.accessToken,
          access_token: data.accessToken ? `Bearer ${data.accessToken}` : "",
          user_roles: data.role,
          user_planName: data.planName,
          user_logIn_Status: true,
          user_customerId: data.customerId,
          user_customerName: data.customerName,
        };
        var loginCookieData = JSON.stringify(cookiesData);
        // Cookies.set("logincustomerinfo", loginCookieData);
      },
      //For successful auto login
      restore: async (data) => {
        dispatch({
          actionType: "LOGIN",
          accessToken: data.accessToken,
          userId: data.userId,
          role: data.role,
          userEmail: data.userEmail,
          tenantId: data.tenantId,
          planName: data.planName,
          customerId: data.customerId,
          customerName: data.customerName,
        });
      },

      //Set this state, to route the flow to Login page
      didTryAutoLogin: () => dispatch({ actionType: "DID_TRY_AUTO_LOGIN" }),
      //For signout flow
      signOut: () => {
        dispatch({ actionType: "LOGOUT" });
        LocalStorageActions.removeAll(KEYS.USER_DATA);
        // Cookies.remove("logincustomerinfo");
      },
    }),
    []
  );

  return (
    <AuthContext.Provider value={{ auth, authActions }}>
      <PartsCartProvider>{children}</PartsCartProvider>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
