import React, { useState } from "react";

const QuantityToggle = ({ qnt }) => {
  const [value, setValue] = useState(qnt);
  const [mouseDownDirection, setMouseDownDirection] = useState(null);

  return (
    <>
      <div className="flex items-center rounded-xl">
        <button
          className={`${
            value <= 1 ? "cursor-not-allowed opacity-50 bg-gray-300 text-gray-500 font-semibold" : "bg-purple-600 text-white "
          } h-6 w-5 pb-1 flex justify-center items-center text-center rounded-l-md`}
          disabled={value <= 1}
        >
          -
        </button>
        <input type="text" name="" id="" className="text-sm h-6 w-7 text-center border border-purple-500" value={value} readOnly />
        <button className="bg-purple-600 text-white h-6 w-5 pb-1 text-center flex justify-center items-center rounded-r-md">+</button>
      </div>
    </>
  );
};

export default QuantityToggle;
