import React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

import { Tooltip } from "@mui/material";

import NumberSpinner from "components/number-spineer/NumberSpinner";
import { currencyFormatter } from "utils/currencyFormatter";
import QuantityToggle from "components/number-spineer/QuantityToggle";

const PartsCard = ({ partItem, isCartItem }) => {
  return (
    <>
      <div className="bg-white p-2 md:p-4 border border-gray-200 shadow rounded" key={partItem?.id}>
        <img
          className="w-full object-contain"
          src={
            partItem?.sparepartImages?.length > 0
              ? partItem?.sparepartImages[0] || "./assets/images/defaultImage.png"
              : "./assets/images/defaultImage.png"
          }
          style={{ height: "100px" }}
          alt={partItem?.partNumber || "Part Image"}
        />
        <div className="px-3 md:p-4">
          <div className="font-semibold text-gray-700 text-sm md:text-xl mb-1">{partItem?.partNumber}</div>
          <p className="text-gray-700 text-xs font-thin truncate">{partItem?.partDescription}</p>
        </div>
        <div className="px-3 md:p-4">
          <div className="font-bold text-orange-500 text-sm md:text-xl mb-1">{currencyFormatter.format(partItem?.listPrice || 0)}</div>
        </div>
        <div className="w-full flex justify-between items-center mt-2 px-3 gap-2">
          <QuantityToggle qnt={partItem?.quantity || 0} />
          {/* <NumberSpinner qnt={partItem?.quantity || 0} /> */}
          {isCartItem ? (
            <>
              <button className="border-2 h-6 w-6 p-1 border-orange-600 text-orange-600 flex justify-center items-center md:hidden rounded-full mx-1">
                <Tooltip title="Delete">
                  <DeleteIcon style={{ fontSize: "16px" }} />
                </Tooltip>
              </button>
            </>
          ) : (
            <Tooltip title={"Add to Cart"}>
              <button className="bg-orange-500 text-white rounded h-6 w-6 flex items-center justify-center p-2">
                <AddShoppingCartIcon style={{ fontSize: "15px" }} />
              </button>
            </Tooltip>
          )}
        </div>
      </div>
    </>
  );
};

export default PartsCard;
