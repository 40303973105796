export const GRID_STYLE = {
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#872ff7",
    color: "#fff",
    fontSize: 12,
  },
  "& .MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
    marginTop: "1em",
    marginBottom: "1em",
  },
  "& .MuiTablePagination-select": {
    marginTop: "1.5em",
    marginBottom: "1.5em",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid rgba(0,0,0,.12)`,
    paddingLeft: "8px",
    paddingRight: "8px",
    minHeight: "72px",
    whiteSpace: "normal !important",
    wordWrap: "break-word !important",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  // minHeight: 300,
  "& .MuiDataGrid-cellContent": {
    fontSize: 12,
  },
  "& .MuiInputBase-root": {
    fontSize: 12,
    marginInline: 2,
    paddingInline: 1,
  },
  "& .super-app-value": {
    backgroundColor: "#dabffd",
    fontWeight: "600",
  },
  "& .disable-value": {
    backgroundColor: "#f2f2f2",
  },
};

export const FONT_STYLE = {
  color: "#872ff7",
  fontSize: "17px",
  fontWeight: "500",
  padding: 0,
};

export const FONT_STYLE_SELECT = {
  placeholder: (provided) => ({
    ...provided,
    ...FONT_STYLE,
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: 10,
    boxShadow: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    ...FONT_STYLE,
  }),
};

export const SELECT_CUSTOM_STYLES = {
  indicatorSeparator: () => ({
    display: "none", // This removes the separator
  }),
  // This targets the dropdown icon (caret) container
  dropdownIndicator: (provided) => ({
    ...provided,
    border: "none", // Remove the border
    padding: "0px", // Optionally adjust padding
  }),
  // Optional: You can also remove the border from the control if needed
  control: (provided) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
  }),
};

// ! ------- Constwnt vairabl of objects --------------------- ! //

export const partsSearchOptions = [
  { label: "Part Number", value: "partNumber" },
  { label: "Description", value: "partDescription" },
  { label: "Model", value: "model" },
];
