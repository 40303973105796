import React, { useEffect, useState } from "react";

import TuneIcon from "@mui/icons-material/Tune";
import SortIcon from "@mui/icons-material/Sort";
import SearchIcon from "@mui/icons-material/Search";
import CachedIcon from "@mui/icons-material/Cached";
import StraightOutlinedIcon from "@mui/icons-material/StraightOutlined";

import { Divider, Tooltip } from "@mui/material";

import $ from "jquery";
import SelectFilter from "react-select";

import { callGetApi } from "api/ApiCaller";
import { API_SUCCESS } from "api/ResponseCode";
import { partsSearch } from "api/searchServices";
import { DATA_SVC_PARTS_MASTER } from "api/ApiConstants";

import CustomizedSnackbar from "./common/CustomSnackBar";
import PartsCard from "./parts-master/PartsCard";
import { partsSearchOptions, SELECT_CUSTOM_STYLES } from "./common/constents";
import LoadingProgress from "./common/LoadingProgress";

const HomeScreen = () => {
  const [partsRecord, setPartsRecord] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSearchRes, setIsSearchRes] = useState(false);
  const [orderBy, setOrderBy] = useState("");

  const [querySearchSelector, setQuerySearchSelector] = useState([
    {
      id: 0,
      selectCategory: "",
      selectOperator: "",
      inputSearch: "",
      selectOptions: [],
      selectedOption: "",
    },
  ]);

  // Snack Bar State
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  useEffect(() => {
    getPartsRecords();
  }, []);

  // get the parts records
  const getPartsRecords = async () => {
    setLoading(true);
    // const rUrl = DATA_SVC_PARTS_MASTER + `?pageNumber=0&pageSize=20`;
    let rUrl = DATA_SVC_PARTS_MASTER + `/search?search=partType:"ACCESSORY"&pageNumber=0&pageSize=20`;
    // if (orderBy) {
    //   rUrl = rUrl + `&sortColumn=id&orderBy=${orderBy}`;
    // }
    callGetApi(
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setPartsRecord(responseData);
          setLoading(false);
        } else {
          setPartsRecord([]);
          setLoading(false);
        }
      },
      (error) => {
        setPartsRecord([]);
        setLoading(false);
      }
    );
  };

  // ! SEarch filter

  // select the filter type options
  const handleSelectCategory = (e, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.selectCategory = e;
    obj.inputSearch = "";
    obj.selectOptions = [];
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
  };

  // input seaarch
  const handleInputSearch = (e, i) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[i];
    let searchString = tempArray[i].selectCategory.value + "~" + e.target.value;
    if (tempArray[i].selectCategory.value && e.target.value) {
      partsSearch(searchString)
        .then((res) => {
          obj.selectOptions = res;
          obj.selectedOption = "";

          tempArray[i] = obj;
          setQuerySearchSelector([...tempArray]);
          $(`.scrollbar-${i}`).css("display", "block");
        })
        .catch((err) => {
          handleSnack("error", "Error occurred while searching spare parts!");
        });
    } else {
      handleSnack("info", "Please fill search criteria!");
      obj.selectOptions = [];
      obj.selectedOption = "";
    }
    obj.inputSearch = e.target.value;
    obj.selectedOption = "";
  };

  // select data from search list
  const handleSearchListClick = (e, currentItem, obj1, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.inputSearch = currentItem[obj.selectCategory.value];
    obj.selectedOption = currentItem;
    obj.selectOptions = [];
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
    $(`.scrollbar-${id}`).css("display", "none");
  };

  // click on search button|icon
  const handleQuerySearchClick = async () => {
    $(".scrollbar").css("display", "none");
    var searchStr = "";
    querySearchSelector.map(function (item, i) {
      if (i === 0 && item.selectCategory.value && item.inputSearch) {
        searchStr = item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      } else if (item.selectCategory.value && item.inputSearch && item.selectOperator.value) {
        searchStr = searchStr + " " + item.selectOperator.value + " " + item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      }
      return searchStr;
    });

    setIsSearchRes(true);
    try {
      if (searchStr) {
        setLoading(true);
        await partsSearch(searchStr)
          .then((result) => {
            if (result && result.length > 0) {
              setPartsRecord(result);
              setLoading(false);
            } else {
              setPartsRecord([]);
              setLoading(false);
            }
          })
          .catch((err) => {
            setPartsRecord([]);
            setLoading(false);
          });
      } else {
        handleSnack("info", "Please fill the search criteria!");
      }
    } catch (err) {
      handleSnack("error", "Error occurred while fetching spare parts!");
    }
  };

  // reset the search filter
  const handleResetSearch = () => {
    setQuerySearchSelector([
      {
        id: 0,
        selectCategory: "",
        selectOperator: "",
        inputSearch: "",
        selectOptions: [],
        selectedOption: "",
      },
    ]);
    setIsSearchRes(false);
    getPartsRecords();
  };

  // sort parts records
  const handleShortRecord = () => {
    if (orderBy === "") {
      setOrderBy("ASC");
    } else if (orderBy == "ASC") {
      setOrderBy("DESC");
    } else if (orderBy == "DESC") {
      setOrderBy("");
    }
  };

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <main className="p-3">
        <div className="">
          <h4 className="mb-1 font-bold text-lg">Search Parts</h4>
          <div className="grid grid-cols-7 items-baseline">
            {querySearchSelector.length !== 0 &&
              querySearchSelector.map((obj, i) => (
                <div className="col-span-6" key={i}>
                  <div className="grid grid-cols-3 items-center border-2 rounded-r-2xl mb-3 rounded md:w-2/5">
                    <div className="col-span-1 rounded-r-xl">
                      <SelectFilter
                        className="border-0 ext-gray-600 rounded-r-lg"
                        options={partsSearchOptions}
                        styles={SELECT_CUSTOM_STYLES}
                        placeholder="Select"
                        value={obj?.selectCategory}
                        onChange={(e) => handleSelectCategory(e, i)}
                      />
                    </div>
                    <div className="col-span-2 flex items-center pl-2 rounded-r-xl">
                      <div className="flex rounded-r-2xl">
                        <div className="relative border-l-2 rounded-r-xl">
                          <input
                            className="custom-input-select text-gray-600 text-base border-0 focus:outline-none focus:ring-0 focus:border-none ml-2 w-full h-full"
                            type="text"
                            placeholder="Search Products"
                            id={`inputSearch-${i}`}
                            autoComplete="off"
                            value={obj.inputSearch}
                            onChange={(e) => handleInputSearch(e, i)}
                          />
                          {obj.inputSearch && obj.inputSearch.length > 0 && (
                            <ul
                              className={`list-group absolute top-full left-0 mt-2 w-full max-h-60 overflow-y-auto bg-white  ${
                                obj.inputSearch.length > 0 && !obj.selectedOption ? "border border-gray-300" : ""
                              } rounded`}
                            >
                              {obj.selectOptions.map((currentItem, j) => (
                                <li key={j} className="py-2 px-4 hover:bg-gray-100" onClick={(e) => handleSearchListClick(e, currentItem, obj, i)}>
                                  {currentItem[obj.selectCategory.value]}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                        <span
                          className="translate-x-4 h-full py-2 px-2 rounded-r-xl border-none cursor-pointer text-white bg-orange-500 sm:hidden"
                          onClick={handleQuerySearchClick}
                        >
                          <SearchIcon className="h-full" style={{ fontSize: "1.3rem" }} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            <div className="col-span-1">
              <span
                className="p-2 flex justify-center h-full translate-x-5 items-center text-xs bg-gray-400 rounded-xl text-white sm:hidden"
                onClick={handleResetSearch}
                style={{ width: "25px", height: "25px" }}
              >
                <Tooltip title="Reset">
                  <CachedIcon className="h-full" style={{ fontSize: "1rem" }} />
                </Tooltip>
              </span>
            </div>
          </div>
          <Divider sx={{ mb: 2 }} />
        </div>

        {loading ? (
          <LoadingProgress />
        ) : (
          <>
            {/* <h4 className="font-medium mb-1">{isSearchRes ? "Results" : "Accessories"}</h4> */}
            <div className="flex justify-between items-baseline mb-2">
              <div>
                <h4 className="font-semibold text-lg mb-0">Accessories</h4>
                <p className="text-sm font-medium text-gray-500">1-{partsRecord.length} of 1514 Results</p>
              </div>
              <div className="flex">
                <span className="mx-2" onClick={handleShortRecord}>
                  <SortIcon sx={{ mx: 0 }} />{" "}
                  {orderBy == "ASC" ? (
                    <StraightOutlinedIcon sx={{ ml: "-5px" }} />
                  ) : orderBy == "DESC" ? (
                    <StraightOutlinedIcon sx={{ ml: "-5px", transform: "rotate(180deg)" }} />
                  ) : (
                    ""
                  )}
                  Sort
                </span>
                <span>
                  <TuneIcon /> Filters
                </span>
              </div>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
              {partsRecord.length !== 0 && partsRecord.map((partItem) => <PartsCard key={partItem?.id} partItem={partItem} />)}
            </div>
          </>
        )}
      </main>
    </>
  );
};

export default HomeScreen;
